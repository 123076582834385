export const apiURL = `${process.env.GATSBY_VG_SERVER_URL}/api`;
export const siteURL = process.env.GATSBY_VG_SITE_URL;
export const localstoragePrefix = "VACKGROUND_DEV";
export const $ = "fsdff";
export const ALERT_INITIAL_STATE = {
  success: false,
  error: false,
  title: "",
  content: "",
};
export const VIEWPORT_SCREEN_SIZE = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  "2xl": 1536,
};
