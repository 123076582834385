// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-account-js": () => import("./../../../src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-affiliate-js": () => import("./../../../src/pages/affiliate.js" /* webpackChunkName: "component---src-pages-affiliate-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-post-js": () => import("./../../../src/pages/blog/post.js" /* webpackChunkName: "component---src-pages-blog-post-js" */),
  "component---src-pages-confirm-to-create-account-js": () => import("./../../../src/pages/confirm-to-create-account.js" /* webpackChunkName: "component---src-pages-confirm-to-create-account-js" */),
  "component---src-pages-confirm-to-download-js": () => import("./../../../src/pages/confirm-to-download.js" /* webpackChunkName: "component---src-pages-confirm-to-download-js" */),
  "component---src-pages-confirm-to-reset-password-js": () => import("./../../../src/pages/confirm-to-reset-password.js" /* webpackChunkName: "component---src-pages-confirm-to-reset-password-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookie-js": () => import("./../../../src/pages/cookie.js" /* webpackChunkName: "component---src-pages-cookie-js" */),
  "component---src-pages-create-account-js": () => import("./../../../src/pages/create-account.js" /* webpackChunkName: "component---src-pages-create-account-js" */),
  "component---src-pages-dashboard-js": () => import("./../../../src/pages/dashboard.js" /* webpackChunkName: "component---src-pages-dashboard-js" */),
  "component---src-pages-license-js": () => import("./../../../src/pages/license.js" /* webpackChunkName: "component---src-pages-license-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-newsletter-js": () => import("./../../../src/pages/newsletter.js" /* webpackChunkName: "component---src-pages-newsletter-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-purchased-js": () => import("./../../../src/pages/purchased.js" /* webpackChunkName: "component---src-pages-purchased-js" */),
  "component---src-pages-refund-js": () => import("./../../../src/pages/refund.js" /* webpackChunkName: "component---src-pages-refund-js" */),
  "component---src-pages-reset-password-js": () => import("./../../../src/pages/reset-password.js" /* webpackChunkName: "component---src-pages-reset-password-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-thanks-for-subscribing-js": () => import("./../../../src/pages/thanks-for-subscribing.js" /* webpackChunkName: "component---src-pages-thanks-for-subscribing-js" */),
  "component---src-templates-categories-js": () => import("./../../../src/templates/categories.js" /* webpackChunkName: "component---src-templates-categories-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-collection-js": () => import("./../../../src/templates/collection.js" /* webpackChunkName: "component---src-templates-collection-js" */),
  "component---src-templates-collections-js": () => import("./../../../src/templates/collections.js" /* webpackChunkName: "component---src-templates-collections-js" */),
  "component---src-templates-faq-js": () => import("./../../../src/templates/faq.js" /* webpackChunkName: "component---src-templates-faq-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-products-js": () => import("./../../../src/templates/products.js" /* webpackChunkName: "component---src-templates-products-js" */),
  "component---src-templates-updates-js": () => import("./../../../src/templates/updates.js" /* webpackChunkName: "component---src-templates-updates-js" */),
  "component---src-templates-why-us-js": () => import("./../../../src/templates/why-us.js" /* webpackChunkName: "component---src-templates-why-us-js" */)
}

