// https://hashinteractive.com/blog/gatsby-authentication-with-strapi-io/
import React, { createContext, useContext, useEffect, useReducer } from "react";
import axios from "axios";

import { apiURL, getCookie, localstoragePrefix, setCookie } from "../helpers";

const authLocalStorageName = `${localstoragePrefix}-auth`;

const DEFAULT_STATE = {
  jwt: null,
  user: {},
  loggedIn: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      // eslint-disable-next-line no-case-declarations
      const { jwt = null, user = {} } = action.payload;
      return { ...state, jwt, user, loggedIn: true };
    case "LOGOUT":
      return { ...state, jwt: null, user: {}, loggedIn: false };
    default:
      return DEFAULT_STATE;
  }
};

const AuthContext = createContext();

// eslint-disable-next-line react/prop-types
const AuthProvider = ({ children }) => (
  <AuthContext.Provider value={useReducer(reducer, DEFAULT_STATE)}>
    {children}
  </AuthContext.Provider>
);

export const wrapRootElement = ({ element }) => (
  <AuthProvider>{element}</AuthProvider>
);

export const useAuth = () => {
  const [state, dispatcher] = useContext(AuthContext);
  const isAuthenticated = state.loggedIn && Object.keys(state.user).length;

  useEffect(() => {
    verifyAuth();
  }, []);

  const verifyAuth = async () => {
    const cookie = getCookie(authLocalStorageName);
    if (cookie) {
      const authData = JSON.parse(getCookie(authLocalStorageName));

      try {
        const { data } = await axios(`${apiURL}/users/${authData.user.id}`, {
          headers: {
            Authorization: `Bearer ${authData.jwt}`,
          },
        });

        if (data.confirmed) {
          dispatcher({ type: "LOGIN", payload: authData });
        }
      } catch {
        setCookie(authLocalStorageName, "", 0);
      }
    }
  };

  const login = async (credentials) =>
    new window.Promise(async (resolve, reject) => {
      try {
        const { data: payload } = await axios.post(
          `${apiURL}/auth/local`,
          credentials
        );
        dispatcher({ type: "LOGIN", payload });
        setCookie(authLocalStorageName, JSON.stringify(payload), 7);
        resolve(payload);
      } catch (e) {
        reject(e);
      }
    });

  const register = async (credentials) =>
    new window.Promise(async (resolve, reject) => {
      try {
        const { data: payload } = await axios.post(
          `${apiURL}/auth/local/register`,
          credentials
        );
        dispatcher({ type: "LOGIN", payload });
        setCookie(authLocalStorageName, JSON.stringify(payload), 7);
        resolve(payload);
      } catch (e) {
        reject(e);
      }
    });
  const logout = () => {
    setCookie(authLocalStorageName, "", 0);
    dispatcher({ type: "LOGOUT" });
  };

  return { state, isAuthenticated, login, register, logout };
};
