export const getStaticSEOImage = (key) => {
  switch (key) {
    case "404":
      return "/assets/thumbnails/404,_We_Lost_This_Page_Featured_Image.jpg";
    case "about":
      return "/assets/thumbnails/About_Us_Featured_Image.jpg";
    case "affiliate":
      return "/assets/thumbnails/Affiliate_Promotion_Featured_Image.jpg";
    case "all-products":
      return "/assets/thumbnails/All_Products_Featured_Image.jpg";
    case "categories":
      return "/assets/thumbnails/Category_Archive_Featured_Image.jpg";
    case "collections":
      return "/assets/thumbnails/Collection_Archive_Featured_Image.jpg";
    case "contact-us":
      return "/assets/thumbnails/Contact_Us_Featured_Image.jpg";
    case "faqs":
      return "/assets/thumbnails/Frequently_Asked_Questions_Featured_Image.jpg";
    case "license":
      return "/assets/thumbnails/License_Featured_Image.jpg";
    case "privacy":
      return "/assets/thumbnails/Privacy_Policy_Featured_Image.jpg";
    case "refund":
      return "/assets/thumbnails/Refund_Featured_Image.jpg";
    case "search":
      return "/assets/thumbnails/Search_on_Vackground_Featured_Image.jpg";
    case "site-updates":
      return "/assets/thumbnails/Site_Updates_Featured_Image.jpg";
    case "terms":
      return "/assets/thumbnails/Terms_of_Service_Featured_Image.jpg";
    case "home":
      return "/assets/thumbnails/Vackground_Home_Feature_Image.jpg";
    case "why-us":
      return "/assets/thumbnails/Why_Us_Featured_Image.jpg";
    default:
      return "/assets/thumbnails/Vackground's_Default_Feature_Image.jpg";
  }
};
