export const createRipple = (e) => {
  const element = e.currentTarget;

  const circle = document.createElement("span");
  const diameter = Math.max(element.clientWidth, element.clientHeight);
  const radius = diameter / 2;

  const { top, left } = element.getBoundingClientRect();
  circle.style.width = circle.style.height = `${diameter}px`;
  circle.style.left = `${e.clientX - left - radius}px`;
  circle.style.top = `${e.clientY - top - radius}px`;
  circle.classList.add("ripple");

  const ripple = element.getElementsByClassName("ripple")[0];

  if (ripple) {
    ripple.remove();
  }

  element.appendChild(circle);
};
