import { useEffect, useRef, useState } from "react";

export const useNonInitialEffect = (func, deps) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) func();
    else didMount.current = true;
  }, deps);
};

export const useMediaQuery = (minWidth) => {
  const isClientSide = typeof window !== "undefined";

  const [state, setState] = useState({
    windowWidth: isClientSide ? window.innerWidth : null,
    isDesiredWidth: false,
  });

  useEffect(() => {
    if (isClientSide) {
      const resizeHandler = () => {
        const currentWindowWidth = window.innerWidth;
        const isDesiredWidth = currentWindowWidth < minWidth;
        setState({ windowWidth: currentWindowWidth, isDesiredWidth });
      };
      window.addEventListener("resize", resizeHandler);
      return () => window.removeEventListener("resize", resizeHandler);
    }
  }, [state.windowWidth]);

  return state.isDesiredWidth;
};
