export const generateHeading = (
  str = "",
  numOfProducts = 0,
  placeholder = "Category"
) => {
  return String(str || placeholder)
    .replace(/{product-count}/, numOfProducts)
    .replace(
      /{product-count-plus}/,
      numOfProducts - 5 >= 1 ? `${numOfProducts - 5}+` : numOfProducts
    )
    .replace(
      /{product-count-rounded-plus}/,
      numOfProducts >= 10
        ? `${numOfProducts - (numOfProducts % 10)}${
            numOfProducts % 10 !== 0 ? "+" : ""
          }`
        : numOfProducts
    );
};
